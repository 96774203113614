import React from 'react';
import './App.css';
import logo from './snippet.png'

import Amplify, {Auth, Hub} from 'aws-amplify';
import {withOAuth} from "aws-amplify-react";
import aws_exports from './aws-exports'

import AceEditor from 'react-ace';

import 'brace/mode/javascript';
import 'brace/theme/monokai';
import Switch from "rc-switch/lib/Switch";
import {Button, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from '@fortawesome/free-solid-svg-icons'
import {CopyToClipboard} from "react-copy-to-clipboard";

Amplify.configure(aws_exports);

const onSave = newValue => {
    console.log('change', newValue);
};

class App extends React.Component {
    state = {
        url: 'https://asdf.x.tims.engineer/',
        authenticated: false,
        jwt: null
    };

    componentDidMount() {
        Auth.currentSession()
            .then(data => {
                // console.log(`Found existing session. data=${JSON.stringify(data['idToken']['jwtToken'])}`)
                console.log(`Found existing session. data=${JSON.stringify(data)}`)
                this.setState({'authenticated': true})
                this.setState({jwt: data['idToken']['jwtToken']})

                fetch(`${process.env.REACT_APP_API}/test`, {
                    crossDomain: true,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.state.jwt}`
                    },
                    cache: 'no-store',
                    credentials: "include"
                });
            })
            .catch(err => {
                console.log(`No existing session. error=${JSON.stringify(err)}`)
                this.setState({'authenticated': false})
            });

        Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    this.setState({'authenticated': true})
                    Auth.currentSession()
                        .then(data => console.log(data))
                        .catch(err => console.log(err));
                    this.setState({...this.state.username, user: data.username});
                    // this.setState({name: 'john'})

                    console.log('Signed in', data)
                    break;
                case "signOut":
                    this.setState({'authenticated': false})
                    console.log('Signed out', data)
                    break;
                default:
                    console.debug(`Unknown hub event. event=${event}, data=${JSON.stringify(data)}`)
            }
        });
    }

    render() {
        return (
            <div>
                <nav className="site-header sticky-top py-1">
                    <div className="container d-flex flex-column flex-md-row justify-content-between">
                        <a className="py-2" href="#">🚀</a>
                        <a className="py-2 d-none d-md-inline-block" href="#">Product</a>
                        <a className="py-2 d-none d-md-inline-block" href="#">Features</a>
                        <a className="py-2 d-none d-md-inline-block" href="#">Support</a>
                        <a className="py-2 d-none d-md-inline-block" href="#">Pricing</a>
                        <a className="py-2 d-none d-md-inline-block" href="#" onClick={this.props.OAuthSignIn}>Sign in/up</a>
                    </div>
                </nav>

                {this.state.authenticated ?
                    <div>
                        <label htmlFor="basic-url">Your vanity URL</label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                https://asdf.x.tims.engineer/
                            </InputGroup.Text>
                            <InputGroup.Append>
                                <CopyToClipboard onCopy={this.onCopy} text={this.state.url}>
                                    <InputGroup.Text id="basic-addon3">
                                        <FontAwesomeIcon icon={faPaperclip}/>
                                    </InputGroup.Text>
                                </CopyToClipboard>
                            </InputGroup.Append>
                        </InputGroup>
                        <AceEditor
                            placeholder="Placeholder Text"
                            mode="javascript"
                            theme="monokai"
                            name="blah2"
                            onLoad={this.onLoad}
                            onChange={this.onChange}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={`function onLoad(editor) {
console.log("i've loaded");
}`}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 2,
                            }}/>
                        <p>async? <Switch
                            onChange={onSave}
                            checkedChildren=" "
                            unCheckedChildren=" "
                            defaultChecked={true}
                        /></p>
                        <Button>Save</Button>
                    </div>
                    : <div>
                        <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
                            <div className="col-md-5 p-lg-5 mx-auto my-5">
                                <h1 className="display-4 font-weight-normal">Webhooks</h1>
                                <p className="lead font-weight-normal">Any logic. Any scale.</p>
                                <a className="btn btn-outline-secondary" onClick={this.props.OAuthSignIn}>Sign up</a>
                            </div>
                            {/*<div className="product-device shadow-sm d-none d-md-block"/>*/}
                            {/*<div className="product-device product-device-2 shadow-sm d-none d-md-block"/>*/}
                        </div>

                        <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
                            <div
                                className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                                <div className="my-3 py-3">
                                    <h2 className="display-5">Real Code</h2>
                                    <p className="lead">Solve problems with regular code</p>
                                </div>
                                {/*<div className={"bg-light shadow-sm mx-auto"}*/}
                                {/*     style={{width: '80%', height: '300px', borderRadius: '21px 21px 0 0'}}>*/}
                                    <img src={logo}/>
                                {/*</div>*/}

                            </div>
                            <div className="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
                                <div className="my-3 p-3">
                                    <h2 className="display-5">Simple pricing</h2>
                                    <p className="lead">No minimums. $0.001/webhook.</p>
                                </div>
                                {/*<div className="bg-light shadow-sm mx-auto"*/}
                                {/*     style={{width: '80%', height: '300px', borderRadius: '21px 21px 0 0'}}>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                }


                <footer className="py-5 bg-dark">
                    <div className="container">
                        <p className="m-0 text-center text-white">Copyright &copy; Your Website 2019</p>
                    </div>
                </footer>
            </div>
        );
    }
}

export default withOAuth(App);