export default {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_3ynm1dLK1',
        userPoolWebClientId: '1eensooh4favb6ls4rk5s2gdog',
        mandatorySignIn: false,
        oauth: {
            domain: process.env.REACT_APP_AUTH_DOMAIN,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_SIGN_OUT,
            responseType: 'code'
        }
    }
}